import React, {useState, useEffect, useRef, useCallback, useMemo} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useReactToPrint } from "react-to-print";
import {
    BLACK_TABLE,
    WHITE_TABLE,
    PURPLE_INFO_UX,
    YELLOW_INFO_UX,
    styleDefaultText,
    GRAY_LABEL_UX,
    BLUE_LIGHTER_UX,
    paperStyle,
    ITENS_PAINEL,
    LINE_TABLE,
    ROW_MAIN,
    WALPAPPER_THEME,
    WHITE_ESCRITA_THEME,
    PAPER_PADDING_THEME,
    LIST_THEME,
    GREEN_SUCCESS_UX, convertLabelToNumber,
} from "../../shared/utils";
import Row from "../../components/Table/Row";
import RowData from "../../components/Table/RowData";
import api from "../../axios";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../components/SelectStore/SelectStore";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import CircleIcon from "@mui/icons-material/Circle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GrayButton from "../../components/Buttons/GrayButton";
import {
	changeOpenComponent
} from "../../actions/StoreActions";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {showSnackMessage} from "../../actions/SnackActions";
import Tour from "reactour";
import { steps } from "./TourPainel";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING } from "../../shared/utils";
import IconButton from "@mui/material/IconButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LastModified from "../../components/LastModified/LastModified";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OptionsMenuComponent from "../../components/Buttons/OptionsMenuComponent";
import GenericModal from "../../components/Modal/GenericModal";

export default function Painel() {
	const [loading, setLoading] = useState(false);
	const [faturamentos, setFaturamentos] = useState([]);
	const [frenteCaixa, setFrenteCaixa] = useState([]);
	const [rowsFrenteCaixa, setRowsFrenteCaixa] = useState([]);
	const [devolucoes, setDevolucoes] = useState([]);
	const [rowsDevolucoes, setRowsDevolucoes] = useState([]);
	const [descontos, setDescontos] = useState([]);
	const [rowsDescontos, setRowsDescontos] = useState([]);
	const [fretes, setFretes] = useState([]);
	const [rowsFretes, setRowsFretes] = useState([]);
	const [receitasBrutas, setReceitasBrutas] = useState([]);
	const [taxasDelivery, setTaxasDelivery] = useState([]);
	const [rowsTaxasDelivery, setRowsTaxasDelivery] = useState([]);
	const [taxasAdquirentes, setTaxasAdquirentes] = useState([]);
	const [rowsTaxasAdquirentes, setRowsTaxasAdquirentes] = useState([]);
	const [taxasVouchers, setTaxasVouchers] = useState([]);
	const [rowsTaxasVouchers, setRowsTaxasVouchers] = useState([]);
	const [receitaLiquida, setReceitaLiquida] = useState([]);
	const [columns, setColumns] = useState([]);
    const [monthsFiltered, setMonthsFiltered] = useState([]);
    const [monthsNotRevisados, setMonthsNotRevisados] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);
    const [previousMonths, setPreviousMonths] = useState([]);
    const [openTour, setOpenTour] =  useState(false);
	const [startAt, setStartAt] = useState(0);
    const [openEmptyRows, setOpenEmptyRows] = useState(false);
    const [taxasCartaoAppsDelivery, setTaxasCartaoAppsDelivery] = useState([]);
    const [rowTaxasIfood, setRowTaxasIfood] = useState([]);
    const [taxasIfood, setTaxasIfood] = useState(rowTaxasIfood);
    const [rowTaxasDeliveryDireto, setRowTaxasDeliveryDireto] = useState([]);
    const [taxasDeliveryDireto, setTaxasDeliveryDireto] = useState(rowTaxasDeliveryDireto);
    const [detalharDRE, setDetalharDRE] = useState(false);
    const [stepsFiltered, setStepsFiltered] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openViewCommentModal, setOpenViewCommentModal] = useState(false);
    const [openInsertCommentModal, setOpenInsertCommentModal] = useState(false);
    const [commentsList, setCommentsList] = useState([]);
    const [comment, setComment] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedField, setSelectedField] = useState("");
    const [columnId, setColumnId] = useState("");
    const [fieldId, setFieldId] = useState("");
    const [selectedColumn, setSelectedColumn] = useState({});

	const [percentActive, setPercentActive] = useState(false);
    const [frenteCaixaPercentage, setFrenteCaixaPercentage] = useState([]);
    const [rowsFrenteCaixaPercentage, setRowsFrenteCaixaPercentage] = useState([]);
    const [devolucoesPercentage, setDevolucoesPercentage] = useState([]);
    const [rowsDevolucoesPercentage, setRowsDevolucoesPercentage] = useState([]);
    const [descontosPercentage, setDescontosPercentage] = useState([]);
    const [rowsDescontosPercentage, setRowsDescontosPercentage] = useState([]);
    const [fretesPercentage, setFretesPercentage] = useState([]);
    const [rowsFretesPercentage, setRowsFretesPercentage] = useState([]);
    const [receitasBrutasPercentage, setReceitasBrutasPercentage] = useState([]);
    const [taxasDeliveryPercentage, setTaxasDeliveryPercentage] = useState([]);
    const [rowsTaxasDeliveryPercentage, setRowsTaxasDeliveryPercentage] = useState([]);
    const [taxasAdquirentesPercentage, setTaxasAdquirentesPercentage] = useState([]);
    const [rowsTaxasAdquirentesPercentage, setRowsTaxasAdquirentesPercentage] = useState([]);
    const [taxasVouchersPercentage, setTaxasVouchersPercentage] = useState([]);
    const [rowsTaxasVouchersPercentage, setRowsTaxasVouchersPercentage] = useState([]);
    const [receitaLiquidaPercentage, setReceitaLiquidaPercentage] = useState([]);
    const [taxasCartaoAppsDeliveryPercentage, setTaxasCartaoAppsDeliveryPercentage] = useState([]);
    const [rowTaxasIfoodPercentage, setRowTaxasIfoodPercentage] = useState([]);
    const [taxasIfoodPercentage, setTaxasIfoodPercentage] = useState(rowTaxasIfood);
    const [rowTaxasDeliveryDiretoPercentage, setRowTaxasDeliveryDiretoPercentage] = useState([]);
    const [taxasDeliveryDiretoPercentage, setTaxasDeliveryDiretoPercentage] = useState(rowTaxasDeliveryDireto);

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isManager = useSelector(state => state.AuthReducer.isManager);
    const dispatch = useDispatch();

	const storeId = useSelector(state => state.StoreReducer.storeId);
    const isOpenPainel = useSelector(state => state.StoreReducer.openComponent);
    
    const tourFrenteCaixa = useMemo(() => (["linha_3", "linha_4","linha_5","linha_6","linha_7",
    "linha_8", "linha_9", "linha_10", "linha_11", "linha_12", "linha_13", "linha_14", "linha_15",
    "linha_16", "linha_17", "linha_18", "linha_19", "linha_20", "linha_21", "linha_22", "linha_23",
    "linha_24", "linha_25", "linha_26"]), []);
    const tourDevolucao = useMemo(() => (["linha_28", "linha_29", "linha_30", "linha_31",
    "linha_32", "linha_33", "linha_34", "linha_35", "linha_36", "linha_37", "linha_38", "linha_39",
    "linha_40", "linha_41", "linha_42", "linha_43"]), []);
    const tourDesconto = useMemo(() => (["linha_45", "linha_46", 
    "linha_47","linha_48", "linha_49", "linha_50", "linha_51", "linha_52", "linha_53", "linha_54",
    "linha_55", "linha_56", "linha_57", "linha_58", "linha_59", "linha_60", "linha_61", "linha_62", 
    "linha_63", "linha_64", "linha_65", "linha_66"]), []);
    const tourFrete = useMemo(() => (["linha_68", "linha_69", "linha_70", "linha_71",
    "linha_72", "linha_73", "linha_74", "linha_75", "linha_76", "linha_77"]), []);
    const tourTaxaApp = useMemo(() => (["linha_81", "linha_82"]), []);
    const tourTaxaIfood = useMemo(() => (["linha_84", "linha_85", "linha_86"]), []);
    const tourTaxaDeliveryDireto = useMemo(() => (["linha_88"]), []);
    const tourTaxaAdquirentes = useMemo(() => (["linha_90", "linha_91", "linha_92", "linha_93",
    "linha_94", "linha_95", "linha_96", "linha_97"]), []);
    const tourTaxaVoucher = useMemo(() => (["linha_99", "linha_100"]), []);

    useEffect(() => {
        let detalhar = window?.dados?.detalhar;
        setDetalharDRE(detalhar);
    }, []);

	useEffect(() => {
		if(storeId !== 0){
			getPainel();
		}
	}, [storeId]);

    useEffect(() => {
        handleChangeSteps();
    }, [openEmptyRows, openTour]);

    const handleChangeSteps = () => {
        setStepsFiltered(steps.filter(step => {
            return document.getElementsByClassName(step.selector.replace(".", "")).length > 0;
        }));
    };

	const handleFilter = useCallback((months) => {
        setMonthsFiltered(months);
		getPainel(months);
	}, [storeId]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

	const getPainel = (monthsFilter = []) => {
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
            style: {
                BLUE_LIGHTER_UX,
                ITENS_PAINEL
            },
			months: monthsFilter
		};
		api.GetPainel(dataRequest).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			let painel = dataResponse.painel;
            let painelPercentage = dataResponse.painel_percentage;
			setColumns(dataResponse.columns);
            setPreviousMonths(dataResponse.filtered_months);
            setMonthsNotRevisados(dataResponse.months_not_revisados);
            let _months = [];
            dataResponse.months.map((month) => {
                _months.push({value: month, label: month});
            });
            setMonthOptions(_months);
			setFaturamentos(painel.painel_faturamento.values);
			setFrenteCaixa(painel.painel_frente_caixa.values);
            setFrenteCaixaPercentage(painelPercentage.painel_frente_caixa.values);
			setRowsFrenteCaixa(painel.painel_frente_caixa.data);
            setRowsFrenteCaixaPercentage(painelPercentage.painel_frente_caixa.data);
			setDevolucoes(painel.painel_devolucoes_cancelamentos.values);
            setDevolucoesPercentage(painelPercentage.painel_devolucoes_cancelamentos.values);
			setRowsDevolucoes(painel.painel_devolucoes_cancelamentos.data);
            setRowsDevolucoesPercentage(painelPercentage.painel_devolucoes_cancelamentos.data);
			setDescontos(painel.painel_descontos.values);
            setDescontosPercentage(painelPercentage.painel_descontos.values);
			setRowsDescontos(painel.painel_descontos.data);
            setRowsDescontosPercentage(painelPercentage.painel_descontos.data);
			setFretes(painel.painel_fretes.values);
            setFretesPercentage(painelPercentage.painel_fretes.values);
			setRowsFretes(painel.painel_fretes.data);
            setRowsFretesPercentage(painelPercentage.painel_fretes.data);
			setReceitasBrutas(painel.painel_receita_bruta.values);
            setReceitasBrutasPercentage(painelPercentage.painel_receita_bruta.values);

			setTaxasDelivery(painel.painel_taxas_delivery.values);
            setTaxasDeliveryPercentage(painelPercentage.painel_taxas_delivery.values);
			setRowsTaxasDelivery(painel.painel_taxas_delivery.data);
            setRowsTaxasDeliveryPercentage(painelPercentage.painel_taxas_delivery.data);
			setTaxasAdquirentes(painel.painel_taxas_adquirentes.values);
            setTaxasAdquirentesPercentage(painelPercentage.painel_taxas_adquirentes.values);
			setRowsTaxasAdquirentes(painel.painel_taxas_adquirentes.data);
            setRowsTaxasAdquirentesPercentage(painelPercentage.painel_taxas_adquirentes.data);
			setTaxasVouchers(painel.painel_taxas_vouchers.values);
            setTaxasVouchersPercentage(painelPercentage.painel_taxas_vouchers.values);
			setRowsTaxasVouchers(painel.painel_taxas_vouchers.data);
            setRowsTaxasVouchersPercentage(painelPercentage.painel_taxas_vouchers.data);
			setReceitaLiquida(painel.painel_receita_liquida.values);
            setReceitaLiquidaPercentage(painelPercentage.painel_receita_liquida.values);
            setRowTaxasIfood(painel.painel_taxas_delivery_ifood.data);
            setRowTaxasIfoodPercentage(painelPercentage.painel_taxas_delivery_ifood.data);
			setTaxasIfood(painel.painel_taxas_delivery_ifood.values);
            setTaxasIfoodPercentage(painelPercentage.painel_taxas_delivery_ifood.values);
            setRowTaxasDeliveryDireto(painel.painel_taxas_delivery_direto.data);
            setRowTaxasDeliveryDiretoPercentage(painelPercentage.painel_taxas_delivery_direto.data);
            setTaxasDeliveryDireto(painel.painel_taxas_delivery_direto.values);
            setTaxasDeliveryDiretoPercentage(painelPercentage.painel_taxas_delivery_direto.values);
			setTaxasCartaoAppsDelivery(painel.painel_taxas_cartao.values);
            setTaxasCartaoAppsDeliveryPercentage(painelPercentage.painel_taxas_cartao.values);
		}).catch(() => {
			setLoading(false);
			setColumns([]);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

    const sendMetadata = useCallback((metadata, value, comment) => {
		setLoadingModal(true);
		const dataRequest = {
			loja_id: storeId ,
			field: fieldId,
			month: columnId,
			comment: comment
		};
		api.PainelMetadata(dataRequest).then(() => {
			setLoadingModal(false);
			if (metadata === "comment"){
				dispatch(showSnackMessage({message: "Comentário salvo com sucesso"}));
				setOpenInsertCommentModal(false);
			}
			getPainel(monthsFiltered);
		}).catch((e) => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			if (e.response.status === 403) {
				dispatch(showSnackMessage({message: e.response.data, severity: "error"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
		});
	}, [storeId, fieldId, columnId, monthsFiltered]);

    const delMetadata = useCallback((metadata, commentId) =>{
		setLoadingModal(true);
        let dataRequest = {
            loja_id: storeId,
            metadata: metadata,
            comment_id: commentId
        };
		api.delMetadataPainel(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
            dispatch(showSnackMessage({message: "Comentário deletado com sucesso!"}));
            setOpenViewCommentModal(false);
			getPainel(monthsFiltered);
		}).catch((e) => {
			setLoadingModal(false);
			setOpenModal(false);
			if (e.response.status === 403) {
				dispatch(showSnackMessage({message: e.response.data, severity: "error"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
		});
	}, [storeId, fieldId, columnId, monthsFiltered]);

    const editComment = useCallback((comment, commentId) => {
		setLoadingModal(true);
		setIsEdit(false);
		const dataRequest = {
            loja_id: storeId,
			comment: comment,
			comment_id: commentId
		};
		api.editCommentPainel(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Comentário editado com sucesso"}));
			getPainel(monthsFiltered);
		}).catch(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [monthsFiltered]);

    const handleAbrirTodos = () => {
		dispatch(changeOpenComponent(!isOpenPainel));
        setDetalharDRE(false);
	};

    const handleRefresh = useCallback((monthsFilter) => {
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			months: monthsFilter
		};
		api.RefreshPainel(dataRequest).then(() => {
			setLoading(false);
			getPainel();
		}).catch(() => {
			setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId]);

    const changeOpen = () => {  
		dispatch(changeOpenComponent(true));
		setStartAt(0);
		setOpenTour(!openTour);
	};

	const openTourSelected = (selector) => {
        const stepIndex = stepsFiltered.findIndex(step => step.selector === `.${selector}`);
        if (stepIndex !== -1) {
            dispatch(changeOpenComponent(true));
            setStartAt(stepIndex);
            setOpenTour(true);
        }
    };

    const changeStatePercent = () => {
		setPercentActive(!percentActive);
	};

    const handleOpenModal = useCallback( (data, index, field, comments) => {
		let column = columns[index + 1];
		let label = column.label;
		let newLabel = convertLabelToNumber(label);

		setCommentsList(comments);
		setColumnId(newLabel);
		setFieldId(field);
		setComment(data.comment);
		setSelectedField(field);
		setSelectedColumn(column);
		if (isAdmin || isManager){
			setOpenModal(true);
		}
	}, [isAdmin, isManager, columns]);

	return (
		<div className="main">
            <LastModified title="Painel" place={"painel"}/>
            {(isAdmin || isManager) && (
                <GenericModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    openViewCommentModal={openViewCommentModal}
                    openInsertCommentModal={openInsertCommentModal}
                    setOpenViewCommentModal={setOpenViewCommentModal}
                    setOpenInsertCommentModal={setOpenInsertCommentModal}
                    commentsList={commentsList}
                    comment={comment}
                    setComment={setComment}
                    editComment={editComment}
                    loadingModal={loadingModal}
                    delMetadata={delMetadata}
                    sendMetadata={sendMetadata}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    selectedField={selectedField}
                    selectedColumn={selectedColumn}
                    component="painel"
                    isPainel
                />
            )}
			<Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
                {loading ? (
                    [...Array(15).keys()].map((i,d) =>{
                        return(
                            <Skeleton key={d} height={50}/>
                        );
                    })
                ) : (
                    storeId === 0 ?
                        (
                            <SelectStore/>
                        ): (
                            <React.Fragment>
                                <Grid container spacing={2} data-testid="painel">
                                    <Grid item xs={12}>
                                        <Grid container sx={{display: "flex", alignItems: "center"}}>
                                            <Grid item sx={{width: "490px"}}>
                                                <FilterWithTag
													options={monthOptions}
													handleSubmit={handleFilter}
													setOptions={setMonthsFiltered}
													monthsFiltered={monthsFiltered}
													previousValue={previousMonths}
													className="button_filter"
												/>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container spacing={GRID_BUTTONS_SPACING} sx={{display: "flex", justifyContent: "end"}}>
                                                    <Grid item>
                                                        <OptionsMenuComponent
                                                            isAdmin={isAdmin}
															handleRefresh={handleRefresh}
															monthsNotRevisados={monthsNotRevisados}
															setMonthOptions={setMonthOptions}
															monthsFiltered={monthsFiltered}
															setMonthsFiltered={setMonthsFiltered}
															optionFilter
															disabled={monthsNotRevisados.length > 0 ? false : true}
                                                            openEmptyRows={openEmptyRows}
                                                            setOpenEmptyRows={setOpenEmptyRows}
															changeStatePercent={changeStatePercent}
                                                            className="button_options"
                                                            component="Painel"
                                                        />
                                                    </Grid>
                                                    <Grid item>
														<GrayButton className="button_export" title="Exportar" startIcon={<FileDownloadIcon/>} onClick={() => handlePrint()} />
													</Grid>
                                                    <Grid item>
                                                        <IconButton style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
															<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
														</IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
										<Grid container>
											<Grid item xs={8}>
												<Grid container spacing={2}>
													<Grid item>
														<Grid container>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{ color: YELLOW_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro && WHITE_ESCRITA_THEME}}>
																<a>Valor Manual</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro && WHITE_ESCRITA_THEME}}>
																<a>Comentário</a>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
                                    <Grid item xs={12}>
                                        <Tour
											steps={stepsFiltered}
											isOpen={openTour}
											onRequestClose={changeOpen}
											accentColor={DASH_BAR_COLOR}
											startAt={startAt}
                                            rounded={5}
                                            showNavigation={false}
                                            inViewThreshold={60}
                                            className={temaEscuro && "tour"}
											badgeContent={(curr, tot) => `${curr}/${tot}`}
										/>
                                        <TableContainer ref={componentRef} className="table-container">
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.label}
                                                                align={column.align}
                                                                style={{
                                                                    ...{zIndex: 3},
                                                                    ...{fontWeight: "bold", },
                                                                    ...{ minWidth: column.minWidth },
                                                                    ...{ backgroundColor: temaEscuro && WALPAPPER_THEME},
                                                                    ...{color: temaEscuro && LINE_TABLE},
                                                                    ...{padding: 0}
                                                                }}
                                                            >
                                                                {(column.label !== "Total" && column.label !== "Período") ? (
																		<Grid container sx={{display: "flex", justifyContent: "center"}}>
																			<Grid item>
																				<Grid container>
																					<Grid item>
																						{column.label}
																					</Grid>
																						{column?.revisada === true && (
																							<Grid item>
																								<CheckCircleIcon style={{marginTop: "3px", fontSize: "18px", color: GREEN_SUCCESS_UX}}/>
																							</Grid>
																						)}
																				</Grid>
																			</Grid>
																		</Grid>
																		) : (
																			column.label
																		)
																	}	
                                                            </TableCell>
                                                        ))}
                                                        <TableCell
															style={{cursor: "pointer", paddingTop: "22px", backgroundColor: temaEscuro && PAPER_PADDING_THEME}}
															onClick={() => handleAbrirTodos()}
														>
															{isOpenPainel ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
														</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <Row
                                                        isCollapse
                                                        openTour={openTour}
                                                        title="Faturamento Bruto"
                                                        rows={faturamentos}
                                                        columns={columns}
														handleOpenModal={handleOpenModal}
                                                        setValues={setFaturamentos}
                                                        backgroundColor={temaEscuro ? ROW_MAIN  : GRAY_LABEL_UX}
                                                        color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                        colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                        fontWeight="bold"
                                                        className="linha_1"
                                                        onClick={() => openTourSelected("linha_1")}
														isTour
                                                    >
                                                        <Row
															isCollapse
                                                            openTour={openTour}
                                                            backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
                                                            title="Frente de Caixa Cliente"
                                                            fontWeight="bold"
                                                            colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            color={temaEscuro ? LINE_TABLE  : BLACK_TABLE}
                                                            rows={frenteCaixa}
                                                            rowsPercent={frenteCaixaPercentage}
															handleOpenModal={handleOpenModal}
                                                            columns={columns}
                                                            setValues={setFrenteCaixa}
                                                            className="linha_2"
                                                            percentActive={percentActive}
                                                        >
															<RowData
																rows={rowsFrenteCaixa}
                                                                rowsPercent={rowsFrenteCaixaPercentage}
																handleOpenModal={handleOpenModal}
																columns={columns}
																setValues={setRowsFrenteCaixa}
                                                                tourColumns={tourFrenteCaixa}
                                                                openEmptyRows={openEmptyRows}
                                                                container={"Painel"}
                                                                percentActive={percentActive}
															/>
														</Row>

                                                        <Row
                                                            isCollapse
                                                            openTour={openTour}
                                                            backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                            title="(-) Devoluções e Cancelamentos "
                                                            fontWeight="bold"
                                                            colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            rows={devolucoes}
                                                            rowsPercent={devolucoesPercentage}
															handleOpenModal={handleOpenModal}
                                                            columns={columns}
                                                            setValues={setDevolucoes}
                                                            className="linha_27"
                                                            percentActive={percentActive}
                                                        >
                                                            <RowData
                                                                rows={rowsDevolucoes}
                                                                rowsPercent={rowsDevolucoesPercentage}
																handleOpenModal={handleOpenModal}
                                                                columns={columns}
                                                                setValues={setRowsDevolucoes}
                                                                tourColumns={tourDevolucao}
                                                                openEmptyRows={openEmptyRows}
                                                                container={"Painel"}
                                                                percentActive={percentActive}
                                                            />
                                                        </Row>


                                                        <Row
                                                            isCollapse
                                                            openTour={openTour}
                                                            backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                            title="(-) Descontos"
                                                            fontWeight="bold"
                                                            colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            rows={descontos}
                                                            rowsPercent={descontosPercentage}
															handleOpenModal={handleOpenModal}
                                                            columns={columns}
                                                            setValues={setDescontos}
                                                            className="linha_44"
                                                            percentActive={percentActive}
                                                        >
                                                            <RowData
                                                                rows={rowsDescontos}
                                                                rowsPercent={rowsDescontosPercentage}
																handleOpenModal={handleOpenModal}
                                                                columns={columns}
                                                                setValues={setRowsDescontos}
                                                                tourColumns={tourDesconto}
                                                                openEmptyRows={openEmptyRows}
                                                                container={"Painel"}
                                                                percentActive={percentActive}
                                                        />
                                                        </Row>
                                                        <Row
                                                            isCollapse
                                                            openTour={openTour}
                                                            backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                            title="(-) Fretes"
                                                            fontWeight="bold"
                                                            colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                            rows={fretes}
                                                            rowsPercent={fretesPercentage}
															handleOpenModal={handleOpenModal}
                                                            columns={columns}
                                                            setValues={setFretes}
                                                            className="linha_67"
                                                            percentActive={percentActive}
                                                        >
                                                            <RowData
                                                                rows={rowsFretes}
                                                                rowsPercent={rowsFretesPercentage}
																handleOpenModal={handleOpenModal}
                                                                columns={columns}
                                                                setValues={setRowsFretes}
                                                                tourColumns={tourFrete}
                                                                openEmptyRows={openEmptyRows}
                                                                container={"Painel"}
                                                                percentActive={percentActive}
                                                        />
                                                        </Row>
                                                    </Row>
                                                    <Row
                                                        isCollapse
                                                        openTour={openTour}
                                                        backgroundColor={temaEscuro ? ROW_MAIN  : GRAY_LABEL_UX}
                                                        colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                        color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                        title="Receita Bruta"
                                                        fontWeight="bold"
                                                        rows={receitasBrutas}
                                                        rowsPercent={receitasBrutasPercentage}
														handleOpenModal={handleOpenModal}
                                                        columns={columns}
                                                        setValues={setReceitasBrutas}
                                                        className="linha_78"
                                                        onClick={() => openTourSelected("linha_78")}
														isTour
                                                        detalharDRE={detalharDRE}
                                                        percentActive={percentActive}
                                                    >
                                                        <Row
                                                            isCollapse
                                                            openTour={openTour}
                                                            backgroundColor={temaEscuro ? LIST_THEME  : GRAY_LABEL_UX}
                                                            colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                            color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                            title="Taxas de Cartão e Apps de Delivery"
                                                            fontWeight="bold"
                                                            rows={taxasCartaoAppsDelivery}
                                                            rowsPercent={taxasCartaoAppsDeliveryPercentage}
                                                            handleOpenModal={handleOpenModal}
                                                            columns={columns}
                                                            setValues={setTaxasCartaoAppsDelivery}
                                                            className="linha_79"
                                                            onClick={() => openTourSelected("linha_79")}
                                                            isTour
                                                            percentActive={percentActive}
                                                        >

                                                            <Row
                                                                isCollapse
                                                                openTour={openTour}
                                                                backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                                title="(-) Taxas Aplicativos Delivery"
                                                                fontWeight="bold"
                                                                colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                rows={taxasDelivery}
                                                                rowsPercent={taxasDeliveryPercentage}
                                                                handleOpenModal={handleOpenModal}
                                                                columns={columns}
                                                                setValues={setTaxasDelivery}
                                                                className="linha_80"
                                                                percentActive={percentActive}
                                                            >
                                                                <RowData
                                                                    rows={rowsTaxasDelivery}
                                                                    rowsPercent={rowsTaxasDeliveryPercentage}
                                                                    handleOpenModal={handleOpenModal}
                                                                    columns={columns}
                                                                    setValues={setRowsTaxasDelivery}
                                                                    tourColumns={tourTaxaApp}
                                                                    openEmptyRows={openEmptyRows}
                                                                    container={"Painel"}
                                                                    percentActive={percentActive}
                                                                />
                                                                <Row
                                                                    isCollapse
                                                                    openTour={openTour}
                                                                    backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                                    title="Ifood"
                                                                    fontWeight="bold"
                                                                    colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                    color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                    rows={taxasIfood}
                                                                    rowsPercent={taxasIfoodPercentage}
                                                                    handleOpenModal={handleOpenModal}
                                                                    columns={columns}
                                                                    setValues={setTaxasIfood}
                                                                    percentActive={percentActive}
                                                                    className="linha_83"
                                                                >
                                                                    <RowData
                                                                        rows={rowTaxasIfood}
                                                                        rowsPercent={rowTaxasIfoodPercentage}
                                                                        handleOpenModal={handleOpenModal}
                                                                        columns={columns}
                                                                        setValues={setRowTaxasIfood}
                                                                        tourColumns={tourTaxaIfood}
                                                                        openEmptyRows={openEmptyRows}
                                                                        container={"Painel"}
                                                                        percentActive={percentActive}
                                                                    />
                                                                </Row>
                                                                <Row
                                                                    isCollapse
                                                                    openTour={openTour}
                                                                    backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                                    title="Delivery Direto"
                                                                    fontWeight="bold"
                                                                    colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                    color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                    rows={taxasDeliveryDireto}
                                                                    rowsPercent={taxasDeliveryDiretoPercentage}
                                                                    handleOpenModal={handleOpenModal}
                                                                    columns={columns}
                                                                    setValues={setTaxasDeliveryDireto}
                                                                    percentActive={percentActive}
                                                                    className="linha_87"
                                                                >
                                                                    <RowData
                                                                        rows={rowTaxasDeliveryDireto}
                                                                        rowsPercent={rowTaxasDeliveryDiretoPercentage}
                                                                        handleOpenModal={handleOpenModal}
                                                                        columns={columns}
                                                                        tourColumns={tourTaxaDeliveryDireto}
                                                                        setValues={setRowTaxasDeliveryDireto}
                                                                        openEmptyRows={openEmptyRows}
                                                                        container={"Painel"}
                                                                        percentActive={percentActive}
                                                                    />
                                                                </Row>
                                                            </Row>
                                                            <Row
                                                                isCollapse
                                                                openTour={openTour}
                                                                backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                                title="(-) Taxas Adquirentes (Cartão)"
                                                                fontWeight="bold"
                                                                colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                rows={taxasAdquirentes}
                                                                rowsPercent={taxasAdquirentesPercentage}
                                                                handleOpenModal={handleOpenModal}
                                                                columns={columns}
                                                                setValues={setTaxasAdquirentes}
                                                                className="linha_89"
                                                                percentActive={percentActive}
                                                            >
                                                                <RowData
                                                                    rows={rowsTaxasAdquirentes}
                                                                    rowsPercent={rowsTaxasAdquirentesPercentage}
                                                                    handleOpenModal={handleOpenModal}
                                                                    columns={columns}
                                                                    setValues={setRowsTaxasAdquirentes}
                                                                    tourColumns={tourTaxaAdquirentes}
                                                                    openEmptyRows={openEmptyRows}
                                                                    container={"Painel"}
                                                                    percentActive={percentActive}
                                                            />
                                                            </Row>
                                                            <Row
                                                                isCollapse
                                                                openTour={openTour}
                                                                backgroundColor={temaEscuro ? LIST_THEME : BLUE_LIGHTER_UX}
                                                                title="(-) Taxas Vouchers Alimentação"
                                                                fontWeight="bold"
                                                                colorTitle={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                color={temaEscuro ? LINE_TABLE : BLACK_TABLE}
                                                                rows={taxasVouchers}
                                                                rowsPercent={taxasVouchersPercentage}
                                                                handleOpenModal={handleOpenModal}
                                                                columns={columns}
                                                                setValues={setTaxasVouchers}
                                                                className="linha_98"
                                                                percentActive={percentActive}
                                                            >
                                                                <RowData
                                                                    rows={rowsTaxasVouchers}
                                                                    rowsPercent={rowsTaxasVouchersPercentage}
                                                                    handleOpenModal={handleOpenModal}
                                                                    columns={columns}
                                                                    setValues={setRowsTaxasVouchers}
                                                                    tourColumns={tourTaxaVoucher}
                                                                    openEmptyRows={openEmptyRows}
                                                                    container={"Painel"}
                                                                    percentActive={percentActive}
                                                            />
                                                            </Row>
                                                        </Row>
                                                    </Row>
                                                    <Row
                                                        backgroundColor={temaEscuro ? ROW_MAIN  : GRAY_LABEL_UX}
                                                        colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                        color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
                                                        title="Receita Líquida"
                                                        fontWeight="bold"
                                                        rows={receitaLiquida}
                                                        rowsPercent={receitaLiquidaPercentage}
                                                        handleOpenModal={handleOpenModal}
                                                        columns={columns}
                                                        setValues={setReceitaLiquida}
                                                        className="linha_101"
                                                        onClick={() => openTourSelected("linha_101")}
														isTour
                                                        percentActive={percentActive}
                                                    />
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )
                )}
            </Paper>
		</div>
	);
}