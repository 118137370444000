import React, { useState, useEffect } from "react";
import {
    paperDash,
    BLUE_THEME,
    styleTextStrong,
    WHITE_THEME_BLACK,
    WHITE_ESCRITA_THEME,
    paperStyleCompras,
    GRAY_LABEL_UX,
    PAPER_PADDING_THEME,
    GRAY_LABEL_UX_THEME,
    YELLOW_BG_UX,
    DASH_KPI_COLOR,
    DASH_KPI_DATA_COLOR,
    DASH_BAR_COLOR,
    GRID_BUTTONS_SPACING,
    BORDER_BUTTON,
    BORDER_TABLE
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import CMVGraph from "../../components/Dash/CMVGraph";
import IntlCurrencyInput from "react-intl-currency-input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import YellowButton from "../../components/Buttons/YellowButton";
import CalculateIcon from "@mui/icons-material/Calculate";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import { stepsCMVSemanal, stepsCMVCalc } from "./TourCMV";
import SelectStore from "../../components/SelectStore/SelectStore";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import GrayButton from "../../components/Buttons/GrayButton";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import LastModified from "../../components/LastModified/LastModified";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {showSnackMessage} from "../../actions/SnackActions";
import InfoModal from "../../components/Modal/InfoModal";
import FormControl from "@mui/material/FormControl";

const CMV = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] =  useState(false);
    const [startAt, setStartAt] = useState(0);
    const [months, setMonths] = useState([]);
    const [monthsAll, setMonthsAll] = useState([]);
    const [previousMonths, setPreviousMonths] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [dataCMV, setDataCMV] = useState([]);
    const [faturamentoLiquidoItens, setFaturamentoLiquidoItens] = useState(0);
    const [faturamentoSemanal, setFaturamentoSemanal] = useState(0);
    const [selectWeek, setSelectWeek] = useState("S1");
    const [monthsCMV, setMonthsCMV] = useState([]);
    const [monthCMVSelected, setMonthCMVSelected] = useState("empty");
    const [estoqueInicial, setEstoqueInicial] = useState(0);
    const [estoqueFinal, setEstoqueFinal] = useState(0);
    const [cmvSemanal, setCmvSemanal] = useState();
    const [cmvMensal, setCmvMensal] = useState();
    const [cmvFaturamento, setCmvFaturamento] = useState();
    const [prazoMedioPagamentos, setPrazoMedioPagamentos] = useState(0.0);
    const [openModal, setOpenModal] = useState(false);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);

    const dispatch = useDispatch();

    useEffect(() => {
        if(storeId !== 0){
            getMesesCompetencia();
            getDashCMV([]);
        }
    }, [storeId]);

    useEffect(() => {
        if (months.length > 0) {
            PatchCMVFaturamentos();
        }
    }, [months]);

    const changeOpen = () => {
        setStartAt(0);
        setOpen(!open);
    };

    const handleChangeTab = (event, newTabIdx) => {
        setSelectedTab(newTabIdx);
    };

    const handleChangeWeek = (event) => {
        setSelectWeek(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setMonthCMVSelected(event.target.value);
    };

    const selectedFirstMonth = () => {
        setMonthCMVSelected(monthsCMV[0]);
        return monthsCMV[0];
    };

    const handleClick = () => {
        getDashCMV();
    };

    const getMesesCompetencia = () => {
        setLoading(true);

        let dataRequest = {
            lojas: [storeId]
        };

        api.GetMesesCompetencia(dataRequest).then(response => {
            setLoading(false);
            let dataResponse = response.data;
            setMonthsAll(dataResponse);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const PatchCMVFaturamentos = () => {
        const dataRequest = {
            loja_id: storeId,
            months,
            select_week: selectWeek,
            select_month: monthCMVSelected === "empty" ? selectedFirstMonth() : monthCMVSelected
        };
        api.PatchCMVFaturamentos(dataRequest).then((response) => {
            let dataResponse = response.data;
            setFaturamentoLiquidoItens(dataResponse.faturamento_liquido_total);
            setFaturamentoSemanal(dataResponse.faturamento_liquido_semanal);
            setPrazoMedioPagamentos(dataResponse.prazo_medio);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const getDashCMV = (storeChanged) => {
        setLoading(true);
        let dataRequest = {
            loja_id: storeId,
            months: storeChanged ? storeChanged : months,
            faturamento_bruto: faturamentoLiquidoItens,
            faturamento_semanal: faturamentoSemanal,
            estoque_inicial: estoqueInicial,
            estoque_final: estoqueFinal,
            select_week: selectWeek,
            select_month: monthCMVSelected
        };
        api.GetDashCMV(dataRequest).then(response => {
                let dataResponse = response.data;
                setDataCMV(dataResponse);
                setCmvSemanal(dataResponse.cmv_s);
                setCmvFaturamento(dataResponse.cmv_fat);
                setCmvMensal(dataResponse.cmv_m);
                setMonthsCMV(dataResponse.list_months);
                let prevMonthsFormatted = dataResponse.list_months.map((month) => month = {
                    "label": month,
                    "value": month
                });
                setPreviousMonths(prevMonthsFormatted);
                dispatch(showSnackMessage({message: "CMV Calculado!", severity: "success"}));
                setLoading(false);
            }
        ).catch((error) => {
            let msgState = error?.response?.data?.msg;
            setLoading(false);
            dispatch(showSnackMessage({message: msgState || "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            setDataCMV([]);
        });
    };

    return (
        <div className="main">
            <LastModified title="CMV" place={"dre"}/>
            {loading ? (
                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
            ) : (
                storeId == 0 ? (
                    <SelectStore/>
                ) : (
                    <Paper sx={{...paperStyleCompras, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}} data-testid="cmv">
                        <InfoModal
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            infoTitle={`Prazo médio de Pagamentos: ${prazoMedioPagamentos} dias`}
                            information="Definição: Mostra, em média ponderada, o prazo de pagamento desde a compra até a quitação do pagamento."
                        />
                        <Grid sx={{marginBottom:2}}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleChangeTab}
                                aria-label="basic tabs example"
                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                            >
                                <Tab
                                    className="tabCMVSemanal"
                                    data-testid={"tabCMVSemanal"}
                                    label="CMV Semanal"
                                    key={0}
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,
                                        backgroundColor: selectedTab === 0 ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: GRAY_LABEL_UX,
                                        },
                                    }}
                                />
                                <Tab
                                    className="tabCMVCalc"
                                    data-testid={"tabCMVCalc"}
                                    label="Cálculo CMV"
                                    key={1}
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,
                                        backgroundColor: selectedTab === 1 ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: GRAY_LABEL_UX,
                                        },
                                    }}
                                />
                            </Tabs>
                        </Grid>
                        <Grid container sx={{display: "flex", alignItems: "center", marginBottom: 2}}>
                            <Grid item sx={{display: "flex", alignItems: "center"}}>
                                <Grid container spacing={GRID_BUTTONS_SPACING} sx={{alignItems: "center"}}>
                                    <Grid item>
                                        <FilterWithTag
                                            optionFilter
                                            options={monthsAll}
                                            setOptions={setMonths}
                                            previousValue={previousMonths}
                                        />
                                    </Grid>
                                    <Grid item sx={{marginRight: 2}}>
                                        <GrayButton
                                            onClick={() => handleClick()}
                                            title="filtrar"
                                            startIcon={<FilterListOutlinedIcon/>}
                                            testId="filtrar"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs sx={{display: "flex", alignItems: "center"}}>
                                <Grid container spacing={GRID_BUTTONS_SPACING} sx={{justifyContent: "end"}}>
                                    <Grid item>
                                        <GrayButton
                                            className="prazo-medio"
                                            onClick={() => setOpenModal(true)}
                                            title={`Prazo médio de Pagamentos: ${prazoMedioPagamentos} dias`}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton data-testid="tour-btn" style={{ background: "#ECC94B", borderRadius: "4px" }} onClick={() => changeOpen()}>
                                            <LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Tour
                                steps={selectedTab == 0 ? stepsCMVSemanal : stepsCMVCalc}
                                isOpen={open}
                                onRequestClose={changeOpen}
                                accentColor={DASH_BAR_COLOR}
                                startAt={startAt}
                                rounded={5}
                                showNavigation={false}
                                inViewThreshold={60}
                                className={temaEscuro && "tour"}
                                badgeContent={(curr, tot) => `${curr}/${tot}`}
                            />
                        </Grid>
                        {selectedTab == 0 && (
                            <Grid item className="CMVGraph" xs={12}>
                                <CMVGraph
                                    testId="cmv-graph"
                                    title="CMV Semanal (R$)"
                                    data={dataCMV}
                                />
                            </Grid>
                        )}
                        {selectedTab == 1 && (
                            <Grid item xs={12}>
                                <Paper sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none", flexDirection: "row"}}>
                                    <Grid container xs={12}>
                                        <Grid container xs={12} spacing={4} style={{alignItems: "flex-end"}}>
                                            <Grid item xs={3} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                                                <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "14px"}}>Fat. Líquido de Itens</a>
                                                <div className="divCurrencyInputNew faturamentoLiquidoItens" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                                                    <IntlCurrencyInput
                                                        value={faturamentoLiquidoItens}
                                                        currency="BRL"
                                                        config={currencyConfig}
                                                        onChange={(event, value) => setFaturamentoLiquidoItens(value)}
                                                        className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                        style={{ ...styleTextStrong, color: temaEscuro ? WHITE_THEME_BLACK : DASH_KPI_DATA_COLOR, border: 0 }}
                                                        data-testid="input-faturamento-bruto"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                                                <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "14px"}}>Fat. Líquido Semanal</a>
                                                <div className="divCurrencyInputNew faturamentoSemanal" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                                                    <IntlCurrencyInput
                                                        value={faturamentoSemanal}
                                                        currency="BRL"
                                                        config={currencyConfig}
                                                        onChange={(event, value) => setFaturamentoSemanal(value)}
                                                        className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                        style={{ ...styleTextStrong, color: temaEscuro ? WHITE_THEME_BLACK : DASH_KPI_DATA_COLOR, border: 0 }}
                                                        data-testid="input-faturamento-bruto"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                                                <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "14px"}}>Estoque Inicial</a>
                                                <div className="divCurrencyInputNew estoqueInicial" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                                                    <IntlCurrencyInput
                                                        value={estoqueInicial}
                                                        currency="BRL"
                                                        config={currencyConfig}
                                                        onChange={(event, value) => setEstoqueInicial(value)}
                                                        className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                        style={{ ...styleTextStrong, color: temaEscuro ? WHITE_THEME_BLACK : DASH_KPI_DATA_COLOR, border: 0 }}
                                                        data-testid="input-prepared-by"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                                                <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "14px"}}>Estoque Final</a>
                                                <div className="divCurrencyInputNew estoqueFinal" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                                                    <IntlCurrencyInput
                                                        value={estoqueFinal}
                                                        currency="BRL"
                                                        config={currencyConfig}
                                                        onChange={(event, value) => setEstoqueFinal(value)}
                                                        className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                        style={{ ...styleTextStrong, color: temaEscuro ? WHITE_THEME_BLACK : DASH_KPI_DATA_COLOR, border: 0 }}
                                                        data-testid="input-prepared-by"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} spacing={3} sx={{display: "flex", justifyContent: "center", alignItems: "end", mt: 0.5}}>
                                            <Grid item>
                                                <FormControl size="small">
                                                    <InputLabel sx={{textAlign: "start", color: BORDER_BUTTON}} id="demo-simple-select-label">Semana</InputLabel>
                                                    <Select
                                                        className="selectSemana"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectWeek}
                                                        label="Semana"
                                                        onChange={handleChangeWeek}
                                                        sx={{width: "138px"}}
                                                    >
                                                        <MenuItem value={"S1"}>S1</MenuItem>
                                                        <MenuItem value={"S2"}>S2</MenuItem>
                                                        <MenuItem value={"S3"}>S3</MenuItem>
                                                        <MenuItem value={"S4"}>S4</MenuItem>
                                                        <MenuItem value={"S5"}>S5</MenuItem>
                                                        <MenuItem value={"S6"}>S6</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl size="small">
                                                    <InputLabel sx={{textAlign: "start", color: BORDER_BUTTON}} id="demo-simple-select-label">Mês</InputLabel>
                                                    <Select
                                                        className="selectMes"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={monthCMVSelected == "empty" ? selectedFirstMonth() : monthCMVSelected}
                                                        label="Mês"
                                                        onChange={handleChangeMonth}
                                                        sx={{width: "138px"}}
                                                    >
                                                        {monthsCMV.map((month) => {
                                                            return (
                                                                <MenuItem key={month} value={month}>{month}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item data-testid="button-calculate" sx={{mb: 1}}>
                                                <YellowButton
                                                    className="calcular"
                                                    title="Calcular"
                                                    onClick={() => getDashCMV()}
                                                    startIcon={<CalculateIcon/>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Grid container xs={12} spacing={2} sx={{marginTop: 1}}>
                                    <Grid item xs={4} height={130}>
                                        <Paper className="cmvFat" sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                            <Grid container sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}} spacing={2}>
                                                <Grid item>
                                                    <span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>CMV / Fat. Bruto</span>
                                                </Grid>
                                                <Grid item>
                                                <span style={{...styles.subTitle, ...(temaEscuro && {color: WHITE_THEME_BLACK})}}>
                                                    {cmvFaturamento}%
                                                </span>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4} height={130}>
                                        <Paper className="cmvSemanal" sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                            <Grid container sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}} spacing={2}>
                                                <Grid item>
                                                    <span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>CMV Semanal</span>
                                                </Grid>
                                                <Grid item>
                                                <span style={{...styles.subTitle, ...(temaEscuro && {color: WHITE_THEME_BLACK})}}>
                                                    {cmvSemanal}%
                                                </span>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4} height={130}>
                                        <Paper className="cmvMensal" sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                            <Grid container sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}} spacing={2}>
                                                <Grid item>
                                                    <span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>CMV Real</span>
                                                </Grid>
                                                <Grid item>
                                                <span style={{...styles.subTitle, ...(temaEscuro && {color: WHITE_THEME_BLACK})}}>
                                                    {cmvMensal}%
                                                </span>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                )
            )}
        </div>
    );
};

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

// Create styles
const styles = {
    title: {
        fontSize: "15px",
        fontWeight: 500,
        color: DASH_KPI_COLOR
    },
    subTitle: {
        fontSize: "20px",
        fontWeight: 600,
        color: DASH_KPI_DATA_COLOR
    },
};

export default CMV;